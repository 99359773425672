import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import PolicyMatters from './pages/PolicyMatters';
import CopyrightMatters from './pages/CopyrightMatters';
import TermCondition from './pages/TermCondition';
import Contact from './pages/Contact';
import About from './pages/About';
import ScrollToTop from './components/ScrollToTop';
import Account from './pages/Account';
import ViewProduct from './pages/ViewProduct';
import ViewCategories from './pages/ViewCategories';
import DistributionForm from './pages/DistributionForm';
import Cart from './pages/Cart';
import Details from './pages/Details';
import Checkout from './pages/Checkout';
import ViewSubcategory from './pages/ViewSubcategory';
import Search from './pages/Search';
import LogoDesign from './pages/LogoDesign';
import DesignWork from './pages/DesignWork';
import WebsiteDesign from './pages/WebsiteDesign';
import ContactModal from './components/ContactModal';

const PageTransitions = () => {
  const location = useLocation(); // Now this is inside the Router context

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames="page"
      >
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/privacy-policy" element={<PolicyMatters />} />
          <Route path="/applyDistributionship" element={<DistributionForm />} />
          <Route path="/copyright" element={<CopyrightMatters />} />
          <Route path="/terms" element={<TermCondition />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/details' element={<Details />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/search' element={<Search />} />
          <Route path="/about" element={<About />} />
          <Route path="/account" element={<Account />} />
          <Route path="/logo-designing" element={<LogoDesign />} />
          <Route path="/designing-work" element={<DesignWork />} />
          <Route path="/website-designing" element={<WebsiteDesign />} />
          <Route path="/viewproduct/:id" element={<ViewProduct />} />
          <Route path="/category/:category" element={<ViewCategories />} />
          <Route path="/subcategory/:subcatId/:subcatName" element={<ViewSubcategory />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

const Layout = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <div>
        <PageTransitions /> {/* Render the transitions here */}
      </div>
      <ContactModal/>
      <Footer />
    </Router>
  );
}

export default Layout;
