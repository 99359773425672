import React, { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

const Checkout = () => {
  const [userDetails, setUserDetails] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [voucherCode, setVoucherCode] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    // Fetch and decode user details from the token
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      // console.log(decodedToken.user.id)
      setUserId(decodedToken.user.id);
      console.log(userId);
    }

    // Fetch user details from localStorage
    const storedUserDetails = JSON.parse(localStorage.getItem('userdetails')) || {};
    setUserDetails(storedUserDetails);

    // Fetch cart items from localStorage
    const storedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCartItems);

    // Calculate total price
    const price = storedCartItems.reduce((total, item) => total + parseFloat(item.totalPrice), 0);
    setTotalPrice(price.toFixed(2));
  }, []);

  const handleApplyVoucher = () => {
    // Implement voucher application logic
    if (voucherCode === 'DISCOUNT10') {
      const discountedPrice = (totalPrice * 0.9).toFixed(2);
      setTotalPrice(discountedPrice);
      alert('Voucher applied successfully!');
    } else {
      alert('Invalid voucher code.');
    }
  };

  const handleOrderNow = async () => {
    const paymentMethod = document.querySelector('input[name="paymentMethod"]:checked')?.value;
  
    const orderDetails = {
      userId,
      userDetails,
      cartItems,
      totalPrice,
      voucherCode,
      paymentMethod,
      orderStatus: 'pending', // default order status
      orderDate: new Date(),
    };
  
    try {
      const response = await axios.post('https://api.payasvinimilk.com/api/orders', orderDetails);
      console.log('Order response:', response.data);
  
      alert('Order placed successfully!');
      // Clear the cart after placing the order
      localStorage.removeItem('cart');
    } catch (error) {
      console.error('Error placing order:', error);
      alert('Failed to place the order. Please try again.');
    }
  };
  

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 flex flex-col sm:flex-row gap-6">
      {/* Left Section: User Details and Payment Methods */}
      <div className="flex-grow">
        <div className="bg-white p-6 rounded-lg shadow-md mb-6 border border-gray-200">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Your Details</h2>
          <p className="text-gray-600"><strong>Name:</strong> {userDetails.name}</p>
          <p className="text-gray-600"><strong>Business Name:</strong> {userDetails.businessName}</p>
          <p className="text-gray-600"><strong>Mobile No:</strong> {userDetails.mobileNo}</p>
          <p className="text-gray-600"><strong>Email:</strong> {userDetails.email}</p>
          <p className="text-gray-600"><strong>Web Address:</strong> {userDetails.webAddress}</p>
          <p className="text-gray-600"><strong>Postal Address:</strong> {userDetails.postalAddress}</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Payment Methods</h2>
          <div>
            <label className="block mb-2 text-gray-700">
              <input type="radio" name="paymentMethod" value="creditCard" className="mr-2" />
              Credit Card
            </label>
            <label className="block mb-2 text-gray-700">
              <input type="radio" name="paymentMethod" value="paypal" className="mr-2" />
              PayPal
            </label>
            <label className="block mb-2 text-gray-700">
              <input type="radio" name="paymentMethod" value="cod" className="mr-2" />
              Cash on Delivery
            </label>
          </div>
        </div>
      </div>

      {/* Right Section: Cart Summary and Voucher */}
      <div className="w-full sm:w-1/3">
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Cart Summary</h2>
          {cartItems.length === 0 ? (
            <p className="text-gray-600">Your cart is empty.</p>
          ) : (
            <ul className="space-y-4">
              {cartItems.map((item) => (
                <li key={item.id} className="flex justify-between items-center text-gray-700">
                  <div>
                    <p><strong>{item.productName}</strong> x {item.quantity}</p>
                  </div>
                  <div>
                    <p>Total: ₹{item.totalPrice}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div className="mt-6">
            <label className="block text-gray-700 font-semibold mb-2">Apply Voucher</label>
            <input
              type="text"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter Voucher Code"
            />
            <button
              onClick={handleApplyVoucher}
              className="mt-2 w-full bg-mainblue text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
            >
              Apply Voucher
            </button>
          </div>
          <div className="mt-6">
            <p className="text-xl font-bold text-gray-800">Total: ₹{totalPrice}</p>
            <button
              onClick={handleOrderNow}
              className="w-full bg-mainpink text-white py-2 rounded-md hover:bg-pink-700 transition mt-4"
            >
              Order Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
