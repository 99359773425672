import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../components/Card"; // Import your Card component

const PAGE_SIZE = 10;

const SearchSection = () => {
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://api.payasvinimilk.com/api/products/getProducts`);
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch products");
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("query") || "";
    filterProducts(query);
  }, [location.search, products, currentPage]);

  const filterProducts = (query) => {
    if (products.length !== 0) {
      const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setDisplayedProducts(filteredProducts.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE));
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(products.filter(product =>
    product.name.toLowerCase().includes((new URLSearchParams(location.search).get("query") || "").toLowerCase())
  ).length / PAGE_SIZE);

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {displayedProducts.map((product) => (
          <Card
            key={product._id}
            name={product.name}
            id={product._id}
            category={product.category.name}
            price={product.price}
            image={product.images[0]} // Display the first image
            onClick={() => navigate(`/product/${product._id}`)} // Navigate to product detail on click
          />
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <button
          className="px-4 py-2 border rounded-lg mr-2 bg-blue-500 text-white hover:bg-blue-600"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
        <span className="self-center">{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className="px-4 py-2 border rounded-lg ml-2 bg-blue-500 text-white hover:bg-blue-600"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SearchSection;
