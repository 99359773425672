import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import PriceTiersModal from "../components/PriceTiersModal";
const ViewProduct = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(100);
  const [customQuantity, setCustomQuantity] = useState(100);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dieShapes, setDieShapes] = useState([]); // State to store die shapes
  const [selectedDieShape, setSelectedDieShape] = useState(""); // State for selected die shape
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://api.payasvinimilk.com/api/products/getProductById/${id}`
        );
        setProduct(response.data);
        console.log(response.data);
        setSelectedImage(response.data.images[0]); // Set the first image as the default selected image
      } catch (error) {
        setError("Failed to fetch product data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  // Fetch die shapes from the API
  useEffect(() => {
    const fetchDieShapes = async () => {
      try {
        const response = await axios.get("https://api.payasvinimilk.com/api/dieshapes");
        setDieShapes(response.data); // Assuming the API returns an array of die shapes
        //setSelectedDieShape(response.data); // Set default die shape if available
      } catch (error) {
        console.error("Failed to fetch die shapes.", error);
      }
    };

    fetchDieShapes();
  }, []);
  const extractYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|embed\/|watch\?v=|watch\?.+&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };
  
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleQuantityChange = (e) => {
    const selectedQty = Number(e.target.value);
    setQuantity(selectedQty);
    setCustomQuantity(selectedQty);
  };

  const handleCustomQuantityChange = (e) => {
    const qty = Number(e.target.value);
    if (qty >= 1) {
      setQuantity(qty);
      setCustomQuantity(qty);
    }
  };

  const getPrice = (quantity) => {
    if (!product || !product.priceTiers || product.priceTiers.length === 0)
      return 0;

    const tiers = product.priceTiers;

    // Find the largest quantity in the tiers that is less than or equal to the provided quantity
    for (let i = tiers.length - 1; i >= 0; i--) {
      if (quantity >= tiers[i].quantity) {
        return tiers[i].price;
      }
    }

    // If no tier matches, return 0 as a fallback
    return 0;
  };

  const handleAddToCart = () => {
    const uniqueId = uuidv4(); // Generate a unique identifier for the cart item
    const cartItem = {
      id: uniqueId,
      productId: id,
      productName: product.name,
      productImage: selectedImage, // Use the selected image
      quantity,
      price: getPrice(quantity),
      totalPrice: (getPrice(quantity) * quantity).toFixed(2),
      dieShape: selectedDieShape, // Add selected die shape to cart item
    };

    console.log("Selected Die Shape:", selectedDieShape); // Log to verify

    // Retrieve the current cart from localStorage or initialize it as an empty array
    const currentCart = JSON.parse(localStorage.getItem("cart")) || [];

    // Add the new cart item to the cart array
    currentCart.push(cartItem);

    // Save the updated cart back to localStorage
    localStorage.setItem("cart", JSON.stringify(currentCart));

    alert("Product added to cart!");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>No product data available.</div>;
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex flex-col md:flex-row gap-4">
        {/* Left Section: Product Images */}
        <div className="md:w-1/2 flex flex-col">
        <div className="mb-4">
          {/* Conditionally render the YouTube video player if the videoUrl exists */}
          {product.videoUrl ? (
            <iframe
              width="100%"
              height="350"
              src={`${product.videoUrl}?`} // YouTube autoplay with mute
              title="Product Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="rounded-lg shadow-md"
            ></iframe>
          ) : (
            <img
              src={selectedImage}
              alt="Product"
              className="w-full h-80 object-cover rounded-lg shadow-md"
            />
          )}
        </div>

        <div className="flex overflow-x-auto space-x-2">
          {/* Conditionally render the thumbnail for the video */}
          {product.videoUrl && (
            <div
              className="flex-shrink-0 w-24 h-24 cursor-pointer"
              onClick={() => setSelectedImage(`https://img.youtube.com/vi/${extractYouTubeID(product.videoUrl)}/hqdefault.jpg`)} // Set the video thumbnail
            >
              <img
                src={`https://img.youtube.com/vi/${extractYouTubeID(product.videoUrl)}/hqdefault.jpg`}
                alt="Video Thumbnail"
                className="w-full h-full object-cover border rounded-lg shadow-md"
              />
            </div>
          )}

          {/* Render the other product images */}
          {product.images.map((image, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-24 h-24 cursor-pointer"
              onClick={() => setSelectedImage(image)} // Update selected image on click
            >
              <img
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-full object-cover border rounded-lg shadow-md"
              />
            </div>
          ))}
        </div>
      
      
        </div>

        {/* Right Section: Product Details */}
        <div className="md:w-1/2 md:pl-6">
          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>

          {/* Die Shape Dropdown */}
          <div className="mb-4">
            {/* Conditionally render the die shape dropdown based on isDieShape */}
            {product.isDieShape && (
              <>
                <h2 className="text-xl font-semibold mb-2">Select Die Shape</h2>
                <select
                  value={selectedDieShape}
                  onChange={(e) => setSelectedDieShape(e.target.value)}
                  className="border rounded-lg py-2 px-4 w-full"
                  required
                >
                  <option value="">Select Die Shape</option>
                  {dieShapes.map((shape) => (
                    <option key={shape._id} value={shape.name}>
                      {shape.name} {/* Use the `name` property to display */}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>

          {/* Quantity and Price */}
          <div className="mb-4">
            <h2 className="text-xl font-semibold mb-2">Select Quantity</h2>
            <select
              value={quantity}
              onChange={handleQuantityChange}
              className="border rounded-lg py-2 px-4 w-full"
            >
              <option value={100}>100 prints</option>
              <option value={200}>200 prints</option>
              <option value={300}>300 prints</option>
              <option value={400}>400 prints</option>
              <option value={500}>500 prints</option>
              <option value={customQuantity}>Other (enter below)</option>
            </select>

            {/* Custom Quantity Input */}
            <div className="mt-2">
              <input
                type="number"
                value={customQuantity}
                min="1"
                className="border rounded-lg py-2 px-4 w-full"
                placeholder="Enter custom quantity"
                onChange={handleCustomQuantityChange}
              />
            </div>

            <p className="mt-2 text-lg font-bold">
              Price: ₹{getPrice(quantity)} per print
            </p>
            <p className="text-gray-500">
              Total Price: ₹{(getPrice(quantity) * quantity).toFixed(2)}
            </p>
          </div>

          {/* Add to Cart Button */}
          <button
            className="bg-mainblue text-white py-2 px-4 rounded-lg hover:bg-blue-700"
            onClick={handleAddToCart}
          >
            Add to Cart
          </button>
          <button
            onClick={handleOpenModal}
            className="bg-mainpink text-white py-2 px-4 rounded-lg hover:bg-pink-700 ml-2"
          >
            Show Price Tiers
          </button>
          <PriceTiersModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            priceTiers={product.priceTiers}
          />
        </div>
      </div>

      {/* Product Description */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Description</h2>
        <p className="text-gray-700">{product.description}</p>
      </div>

      {/* Related Products */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Related Products</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {/* Dummy related products */}
          {product.images.map((image, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img
                src={image}
                alt={`Related Product ${index + 1}`}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  Related Product {index + 1}
                </h3>
                <p className="text-mainpink font-bold">₹{200}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
