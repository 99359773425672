import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const ViewCategories = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/categories/getCategoryById/${category}`);
        setCategoryDetails(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load category details.');
        setLoading(false);
      }
    };

    fetchCategoryDetails();
  }, [category]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!categoryDetails) return <p>Category not found.</p>;

  const { name, image, subcategory } = categoryDetails;

  const handleSubcategoryClick = (subcatId,subcatName) => {
    navigate(`/subcategory/${subcatId}/${subcatName}`);
  };

  return (
    <div className="my-2">
      <div className="mb-8">
        <img
          src={image}
          alt={`${name} Banner`}
          className="min-w-full h-64 object-cover shadow-lg"
        />
      </div>
      <div className="max-w-7xl mx-auto p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">{name}</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {subcategory.length > 0 ? (
            subcategory.map((subcat) => (
              <div
                key={subcat.name}
                className="bg-white rounded-lg shadow-lg overflow-hidden cursor-pointer"
                onClick={() => handleSubcategoryClick(subcat._id,subcat.name)}
              >
                <img
                  src={subcat.imgPath}
                  alt={subcat.name}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-800">
                    {subcat.name}
                  </h3>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600">No subcategories available in this category.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCategories;
