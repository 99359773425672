import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState(""); // For handling error messages
  const [loading, setLoading] = useState(false); // For showing loading state

  const navigate = useNavigate(); // To redirect after registration

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when starting the request
    setError(""); // Clear previous errors

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false); // Reset loading state
      return;
    }

    try {
      const response = await axios.post(
        `https://api.payasvinimilk.com/api/user/register`,
        formData
      );

      if (response.data.success) {
        console.log("Registration successful:", response.data);
        // Redirect to the login page
        navigate("/login");
      } else {
        setError(response.data.message); // Display server error message
      }
    } catch (error) {
      console.error("An error occurred during registration:", error.message);
      setError("An error occurred during registration. Please try again."); // Display generic error message
    } finally {
      setLoading(false); // Reset loading state after request is done
    }
  };

  return (
    <div className=" mt-20 mb-20 flex items-center justify-center">
      <div className="max-w-lg w-full mx-auto bg-white shadow-xl border-gray-300 rounded-lg p-8 md:p-12 flex flex-col">
        <h2 className="text-2xl font-extrabold text-mainpink mb-4">
          Create New Account
        </h2>
        <hr className="border-t-2 border-lightpink mb-6" />

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          <div>
            <label htmlFor="confirmPassword" className="block text-gray-700 mb-1">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              required
            />
          </div>

          {error && (
            <p className="text-red-500 text-center mt-4">{error}</p>
          )}

          <button
            type="submit"
            className="w-full bg-mainpink text-white py-3 rounded-lg hover:bg-pink-700 transition duration-300"
            disabled={loading} // Disable button while loading
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </form>

        <p className="text-center text-gray-600 mt-4">
          Already have an account?{" "}
          <Link to="/login" className="text-mainpink hover:underline">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
